$FontSans: 'Source Sans Pro', sans-serif;
$bgcolor: #f7f9fa;
$white: #ffffff;
$black: #000000;
$TxtGray: #374347;
$mid-grey: #b3c6cd;
$red: #f5474a;
$red_btn: #f84545;
$green: #44b5ab;
$frenchPass: #b3eafb;
$blackSqueeze: #e1eef3;
$bonjour: #e7e4e5;
$limedSpruce: #364347;
$grey: #666666;
$greyC: #cccccc;
$greyE6: #e6e6e6;
$greyA7: #a7a7a7;
$heather: #b2c6cd;
$grayB0: #b0b0b0;
$greyA776: #a7a7a6;
$lighter-blue: #d6e1e5;
$light-blue: #02baf2;
$dark-blue: #199ecb;
$hit_gray: #93a4aa;
$maroon: #9c0e17;
$darker-red: #bb0a30;
$charcoalGrey: #4a4a4a;
$heather: #b2c6cd;
$castWhite: #e2eef3;
$greenShade0: #009a44;
$greenShade1: #3eb5ac;
$black40opac: rgba(0, 0, 0, 0.4);
$azureBlue: #0478ff;
$dark-grey: #a9a9a9;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  transition: background-color 0.5s;
  font-family: $FontSans;
  background: $white; /*background:$bgcolor;*/
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  outline: none;
}
//.anim { transition: all 0.4s ease; -webkit-transition: all 0.4s ease; -ms-transition: all 0.4s ease; -moz-transition: all 0.4s ease; -o-transition: all 0.4s ease; }
.container {
  max-width: 768px;
}

.standard_line_height * {
  line-height: 120% !important;
}

.txtBlack {
  color: $black !important;
}
.TxtRed {
  color: $red !important;
}

p {
  margin: 0 0 10px 0;
  line-height: 130%;
}

.p0 {
  padding: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
}
.pr0 {
  padding-right: 0 !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pt0 {
  padding-top: 0 !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt8 {
  padding-top: 8px !important;
}
.pb0 {
  padding-bottom: 0 !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb70 {
  padding-bottom: 70px !important;
}
.pb112 {
  padding-bottom: 112px !important;
}
.pb125 {
  padding-bottom: 125px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt70 {
  padding-top: 70px !important;
}
.pt55 {
  padding-top: 55px !important;
}

.m0 {
  margin: 0 !important;
}
.ml0 {
  margin-left: 0 !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr0 {
  margin-right: 0 !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt55 {
  margin-top: 55px !important;
}
.mt70 {
  margin-top: 70px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.safeco-app .safecoPolicyDetail .header .title {
  color: rgba(54, 67, 71, 0.8);
  font-size: 20px;
}
.safeco-app .safecoPolicyDetail .bottom_fix {
  padding: 20px;
}
.safeco-app .safecoPolicyDetail .bottom_fix .btn {
  text-transform: none;
  padding: 10px 15px;
}
.safeco-app .safecoPolicyDetail .text-area input.question + label > span,
.safeco-app .safecoPolicyDetail .text-area textarea.question + label > span {
  font-size: 18px;
}
.safeco-app .safecoPolicyDetail .text-area input.question:focus + label > span,
.safeco-app .safecoPolicyDetail .text-area input.question:valid + label > span {
  font-size: 13px;
  width: auto;
}
.safeco-app .newforDemoJune2018 .header .title,
.rsa-app .newforDemoJune2018.policyDetailScreen .header .title {
  color: rgba(54, 67, 71, 0.8);
  font-size: 20px;
}
.safeco-app .newforDemoJune2018 .home-footer .btn,
.rsa-app .policyDetailScreen .home-footer .btn {
  text-transform: none;
  padding: 10px 15px;
}
.safeco-app .newforDemoJune2018 .home-footer .btn,
.rsa-app .policyDetailScreen .home-footer .btn {
  margin-bottom: 0;
}
.safeco-app .safecoPolicyFound .bold-title,
.rsa-app .policyDetailScreen .safecoPolicyFound .bold-title {
  font-weight: 700;
}
.safeco-app .safecoPayment .bottom_fix {
  background: #f7f9fa;
}
.safeco-app .safecoPayment .bottom_fix .btn {
  text-transform: none;
  padding: 10px 15px;
}
.safeco-app .safecoPayment .form_area .card_number {
  position: relative;
}
.safeco-app .bottom_fix.safeco_btn .btn {
  text-transform: none;
}
.safeco-app .bottom_fix .btnClose:hover {
  background: #e1eef3 !important;
  color: #364347 !important;
}
.safeco-app .list_data .besideButton .btn:hover,
.safeco-app .list_data .besideButton .btn:active,
.safeco-app .list_data .besideButton .btn:focus {
  background: #ffffff !important;
  color: #02baf2 !important;
}
.safeco-app .bottom_fix .btn:hover {
  background: #02baf2;
  color: #ffffff;
}
.box_search.box_bottom .box_bottom_content h2 {
  font-size: 20px;
  color: #364347;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
}
.locate_policy_modal .btn,
.locate_policy_modal .btn:visited,
.locate_policy_modal .btn:active,
.locate_policy_modal .btn:focus {
  width: 100%;
  border-radius: 4px;
  padding: 10px 15px;
  background: #02baf2;
  box-sizing: border-box;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  max-width: 257px;
  margin-bottom: 15px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swal2-show * {
  font-family: $FontSans;
}
.swal2-show {
  min-height: 1px !important;
  border-radius: 25px;
  width: 100%;
  max-width: 315px;
  padding: 0 !important;
}
.swal2-show .swal2-title {
  padding: 20px 15px 0 15px;
  margin: 0 0 10px 0;
  color: $black;
  width: 100%;
  float: left;
  font-size: 22px;
  font-weight: 700;
  font-family: $FontSans;
  line-height: 130%;
}
.swal2-show .swal2-content {
  width: 100%;
  float: left;
  padding: 0 15px 20px 15px;
  color: $black;
  font-size: 18px;
  font-weight: 400;
  font-family: $FontSans;
  line-height: 130%;
}
.swal2-show .swal2-buttonswrapper {
  border-top: 1px solid #eeeeee;
  width: 100%;
  float: left;
  margin: 0;
}
.swal2-modal .swal2-styled {
  margin: 5px;
  background: transparent !important;
  border: 0 !important;
  box-shadow: 0 0 0 !important;
  color: $azureBlue;
  font-size: 22px;
}
// .swal2-confirm.swal2-styled {
//   float: right;
// }

.loader {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  transition: 1s all ease;
  background: rgba(0, 0, 0, 0.1);
}
.loader * {
  transition: 1s all ease;
}
.hide {
  display: none !important;
}
.loader.show {
  opacity: 1;
  z-index: 1000;
}
.loaderbg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 103;
  background: rgba(0, 0, 0, 0.4);
}
.loaderbg .loader_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.loaderbg .loader_content .loaderText {
  color: #fff;
  animation: blink 2s linear infinite;
}
.loaderbg .loader_content .loaderimg {
  width: 70px;
  height: 70px;
  display: inline-block;
}
.loaderbg .loader_content .loaderimg img {
  width: 100%;
  opacity: 0.5;
}

.payment_pg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $bgcolor;
}

.container {
  max-width: 768px;
}

.two_column_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box_search .form-group {
  width: 100%;
  float: left;
  margin: 0 0 20px 0;
}

// BMW Payment_screen
.new_textbox {
  .form-group{
    margin-bottom: 22px;
  }
  label {
    font-size: 13px;
    font-weight: normal;
    line-height: normal;
    color: #93a4aa;
    text-transform: uppercase;
    margin-bottom: 2px;
  }
  .form-control {
    font-size: 19px;
    font-weight: normal;
    line-height: 0.95;
    color: #364347;
    height: 50px;
    &:hover,
    &:focus {
      box-shadow: none;
      border-color: #ccc;
    }
  }
  .icon_textbox {
    position: relative;
    width: 100%;
    display: block;
    .form-control {
      padding-right: 50px;
      position: relative;
    }
    i {
      position: absolute;
      right: 12px;
      top: 17px;
      &:before{
        font-size: 18px;
      }
    }
  }
}

.payment_wrap {
  padding: 0px 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    color: #364347;
    padding: 15px 0px 10px 0px;
  }
  .desc {
    font-size: 15px;
    font-weight: normal;
    line-height: normal;
    color: #3d4c51;
    max-width: 378px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
  }
  .payment_item_detail {
    font-size: 15px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #93a4aa;
    margin-bottom: 24px;
    .total {
      font-weight: bold;
      padding: 3px 0px;
    }
  }
  .payment_form {
    .cvv_textbox {
      width: 50%;
    }
  }
}
.payment_term {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: #364347;
  .terms {
    font-weight: bold;
    color: #02baf2;
  }
}
.payment_bottom {
  bottom: 26px;
  background: transparent;
}
.thanks_wrapper {
  text-align: center;
  padding: 120px 20px 20px 20px;
  .thanks_title {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: #364347;
  }
  .thanks_text {
    padding: 20px 0px;
    font-size: 16px;
    font-weight: normal;
    color: #3d4c51;
  }
}
.black_btn {
  display: block;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
  background-color: #364347;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  padding: 10px 12px;
  margin: 0 auto;
}
.black_btn:hover,
.black_btn:active,
.black_btn:focus {
  color: #ffffff;
  background-color: #364347;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.bottom_fix {
  text-align: center;
  padding: 15px 20px;
  box-sizing: border-box;
  z-index: 10;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
.payment_form .ic_green_tick {
  position: absolute;
  text-decoration: none;
  top: 14px;
  right: 10px;
  width: 23px;
  height: 23px;
  background: $green;
  border-radius: 50%;
  text-align: center;
  line-height: 23px;
}
.form_area .card_number .ic_green_tick .fa {
  color: $white;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  font-size: 16px;
}
.form_area .expDate .ic_green_tick {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 15px;
  background: $greenShade1;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .expDate .ic_green_tick .fa {
  color: $white;
}
.form_area .zipcode .ic_green_tick {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0;
  background: $greenShade1;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .zipcode .ic_green_tick .fa {
  color: $white;
}
.form_area .cvv .ic_green_tick {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 0px;
  right: 0;
  background: $greenShade1;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
}
.form_area .cvv .ic_green_tick .fa {
  color: $white;
}
.safeco-app .safecoPayment .form_area .card_number .ic_green_tick {
  top: 0;
  right: 0;
}

.thanks_page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $bgcolor;
  position: relative;
}
.thanks_box {
  max-width: 320px;
  text-align: center;
}
.thanks_hd {
  font-size: 24px;
  color: $limedSpruce;
  font-weight: 700;
  margin-bottom: 5px;
}
.thanks_info {
  font-size: 17px;
  color: $limedSpruce;
  font-weight: 400;
  line-height: 25px;
}
// BMW Payment_screen

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error_msg{
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f84545;
  position: absolute;
  margin-top: 3px;
}
